angular.module('core').controller('MobileBannerController', [
    '$rootScope',
    '$scope',
    '$location',
    '$state',
    'mobileBannerService',
    'DetectMobileBrowser',
    'OpenPopupForm',
    'OpenDeepLink',
    'NIIO_APP',
    function (
        $rootScope,
        $scope,
        $location,
        $state,
        mobileBannerService,
        DetectMobileBrowser,
        OpenPopupForm,
        OpenDeepLink,
        NIIO_APP
    ) {
        $scope.showDesktop = false;
        $scope.showMobile = false;
        $scope.showMobileStrip = true;
        $scope.bottom = '0px';
        $scope.getBottomValue = mobileBannerService.getBottomValue;
        $scope.fromIframe = $location.$$search.fromIframe ?? false;
        $scope.couponCode = $location.$$search.campaignId ?? null;
        $scope.deviceId = $location.$$search.deviceId ?? $rootScope?.lastStateParamsBeforeLogin.deviceId ?? null;
        $scope.shortSecret =
            $location.$$search.shortSecret ?? $rootScope?.lastStateParamsBeforeLogin.shortSecret ?? null;
        $scope.deviceName = $location.$$search.deviceName ?? $rootScope?.lastStateParamsBeforeLogin.deviceName ?? null;
        $scope.deviceType = $location.$$search.deviceType ?? $rootScope?.lastStateParamsBeforeLogin.deviceType ?? null;
        $scope.OpenPopupForm = OpenPopupForm;

        $scope.close = function () {
            $scope.showMobile = false;
            $scope.showDesktop = false;
            $scope.showMobileStrip = false;
            mobileBannerService.markClosed();
        };

        $scope.closeMobileBanner = () => {
            $scope.showMobile = false;
            mobileBannerService.markCollapsed();
        };

        $scope.isMobile = mobileBannerService.isMobile();
        $scope.isIOS = DetectMobileBrowser.isIOS();
        $scope.mobileActionTitle = '';
        $scope.mobileActionSubTitle = 'Join & Connect Your Device to Niio';
        $scope.mobileActionicon = '/modules/core/img/niio_app.svg';
        $scope.campaignUrl = '';

        $scope.downloadLink = function () {
            let link;
            if ($scope.campaignUrl) {
                link = $scope.campaignUrl;
            } else {
                link = 'https://niioart.onelink.me/QVcR/artgift';

                // OpenDeepLink.func(NIIO_APP).then(
                //     function (res) {
                //         // Successfully opened the link in the app
                //     },
                //     function (err) {
                //         // Continue with regular web
                //         if ($scope.isIOS) {
                //             window.open('https://apps.apple.com/us/app/niio-art/id1489485883', '_blank');
                //         } else {
                //             window.open(
                //                 'https://play.google.com/store/apps/details?id=com.niioart.app&hl=en',
                //                 '_blank'
                //             );
                //         }
                //     }
                // );
            }

            // Add query params to the link if each exist
            // Create URL object to safely manage query params
            const url = new URL(link);
            const params = new URLSearchParams(url.search); // Preserve existing query params

            // Append new params only if they exist
            if ($scope.deviceId) params.set('deviceId', $scope.deviceId);
            if ($scope.shortSecret) params.set('shortSecret', $scope.shortSecret);
            if ($scope.deviceName) params.set('deviceName', $scope.deviceName);
            if ($scope.deviceType) params.set('deviceType', $scope.deviceType);

            // Update the URL with new query params
            url.search = params.toString();

            // Open the updated link
            window.open(url.toString(), '_blank');
        };

        $scope.init = function (forceShow = false) {
            const needToShow = !mobileBannerService.isClosed() && !$scope.fromIframe;
            const isMobile = mobileBannerService.isMobile();
            const isCollapsed = mobileBannerService.isCollapsed();

            // Get coupon from server
            if ($scope.couponCode) {
                // const providerName = $scope.isIOS ? 'apple' : 'charge_bee';
                const providerName = 'apple';
                mobileBannerService.getCoupon(providerName, $scope.couponCode).then((response) => {
                    if (response.data) {
                        let coupon = response.data.coupon;
                        $scope.campaignUrl = coupon?.distribution_conditions?.campaign_link ?? '';
                    }
                });
            }

            $scope.showDesktop = needToShow && !isMobile;
            $scope.showMobile = forceShow || (needToShow && !isCollapsed && isMobile);
            $scope.showMobileStrip = needToShow && isMobile;
        };

        function getMobileActionContent(action) {
            switch (action) {
                case 'cast':
                    $scope.mobileActionTitle = 'Play on any screen.';
                    $scope.mobileActionicon = `/modules/core/img/cast-circle.svg`;
                    break;
                case 'add-to-playlist':
                    $scope.mobileActionTitle = 'Create your own playlist.';
                    $scope.mobileActionicon = `/modules/core/img/add-to-playlist-circle.svg`;
                    break;
                case 'add-to-queue':
                    $scope.mobileActionTitle = 'Create a personalized queue.';
                    $scope.mobileActionicon = `/modules/core/img/add-to-queue-circle.svg`;
                    break;
            }
        }

        $rootScope.$on('openMobilePopup', (event, data) => {
            $scope.showMobile = true;
            $scope.mobileActionSubTitle =
                'Available on the new Niio mobile app, together with many other exciting features.';
            getMobileActionContent(data.action);
        });

        $scope.init();

        $rootScope.$on('openMobileBanner', () => {
            $scope.init(true);
        });
    },
]);
