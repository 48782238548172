'use strict';

angular.module('core').service('OpenPopupForm', [
    '$rootScope',
    '$q',
    '$timeout',
    'Messages',
    function ($rootScope, $q, $timeout, Messages) {
        this.openContact = function (type, email, message, subType) {
            var params = {
                type,
                subType,
                email,
                message,
            };

            var messageParams = {};
            messageParams.size = 'md';
            messageParams.hideHeader = true;
            messageParams.hideFooter = true;
            messageParams.disableAutoDismiss = true;
            messageParams.template = 'modules/core/views/core.contact.client.view.html';
            messageParams.templateParams = params;
            return Messages.openMessage($rootScope, messageParams);
        };

        this.openMenu = function (name, params) {
            var messageParams = {};
            //messageParams.size = 'lg';
            messageParams.title = name.charAt(0).toUpperCase() + name.slice(1);
            messageParams.disableAutoDismiss = true;
            messageParams.hideFooter = true;
            messageParams.hideHeader = true;
            messageParams.templateParams = Object.assign({}, params);
            if (name === 'join') {
                messageParams.backdrop = 'static';
                messageParams.template = 'modules/users/views/join.client.view.html';
                if ($rootScope.isSmartPhone) {
                    $timeout(function () {
                        $rootScope.$broadcast('openMobileBanner');
                    });
                    return;
                }
            } else if (name === 'login') {
                messageParams.backdrop = 'static';
                messageParams.template = 'modules/users/views/login.client.view.html';
            } else if (name === 'invitation') {
                messageParams.backdrop = 'static';
                messageParams.template = 'modules/users/views/invitation.client.view.html';
            } else if (name === 'deviceDebugging') {
                messageParams.template = 'modules/devices/views/devices.selected.debugging.client.view.html';
            } else if (name === 'social') {
                messageParams.template = 'modules/core/views/social-share.client.view.html';
            } else if (name === 'share') {
                messageParams.template = 'modules/share/views/share-artwork.client.view.html';
            } else if (name === 'shareChannel') {
                messageParams.template = 'modules/share/views/share-channel.client.view.html';
            } else if (name === 'inquire') {
                messageParams.template = 'modules/core/views/core.inquire.client.view.html';
            } else if (name === 'artworkManagement') {
                messageParams.size = 'lg';
                messageParams.template = 'modules/home/views/home.artwork-management.client.view.html';
            } else if (name === 'plans') {
                messageParams.size = 'xxl';
                // messageParams.backdrop = 'static';
                messageParams.template = 'modules/billing/views/billing.plans.client.view.html';
            } else if (name === 'plan-subscribe') {
                messageParams.backdrop = false;
                messageParams.template = 'modules/billing/views/billing.plan-subscribe.client.view.html';
            } else if (name === 'channelManagement') {
                messageParams.size = 'lg';
                messageParams.template = 'modules/home/views/home.channel-management.client.view.html';
            } else if (name === 'invitationUsers') {
                messageParams.size = 'lg';
                messageParams.template = 'modules/admin-area/views/admin-area.invitation-users.client.view.html';
            } else if (name === 'termsPaste') {
                messageParams.size = 'lg';
                messageParams.scope = params.scope;
                messageParams.template =
                    'modules/admin-area/views/admin-area.events.selected.terms-paste.client.view.html';
            } else if (name === 'adminAreaStatusesArtworksTasks') {
                messageParams.size = 'lg';
                messageParams.template = 'modules/admin-area/views/admin-area.statuses.artworks-tasks.client.view.html';
            } else if (name === 'uploads') {
                messageParams.template = 'modules/core/views/core.uploads.client.view.html';
                messageParams.windowClass = 'set-even-lower-z-index';
                messageParams.backdropClass = 'set-even-lower-z-index-backdrop';
                messageParams.size = 'lg';
            } else if (name === 'channelInfoArtwork') {
                messageParams.size = 'lg';
                messageParams.backdrop = true;
                messageParams.template =
                    'modules/channel-info/views/channel-info.channel.artwork-info-modal.client.view.html';
            } else if (name === 'artworkGalleryModal') {
                messageParams.size = 'lg';
                messageParams.backdrop = true;
                messageParams.template = 'modules/core/views/core.artwork-gallery-modal.client.view.html';
            } else if (name === 'uploadsFirstTime') {
                messageParams.title = 'Edit Your Artwork Curatorial Metadata';
                messageParams.hideFooter = false;
                messageParams.hideHeader = false;
                messageParams.okText = 'OK, Got it!';
                messageParams.template = 'modules/home/views/home.first-time-upload.client.view.html';
            } else if (name === 'upload') {
                messageParams.size = 'md';
                messageParams.backdrop = false;
                // messageParams.backdropClass = 'set-lower-z-index-backdrop';
                messageParams.windowClass = 'set-lower-z-index';
                messageParams.template = 'modules/home/views/home.upload-modal.client.view.html';
            } else if (name === 'niio-sdk') {
                messageParams.size = params.size || 'lg';
                messageParams.backdrop = true;
                messageParams.hideHeader = params.hideHeader || false;
                messageParams.hideFooter = true;
                messageParams.hideTitle = true;
                messageParams.hideLogo = params.hideLogo || false;
                // messageParams.backdropClass = 'set-lower-z-index-backdrop';
                // messageParams.windowClass = 'set-lower-z-index';
                messageParams.template = 'modules/home/views/home.niio-sdk-modal.client.view.html';
            } else if (name === 'uppy-error') {
                messageParams.backdrop = 'static';
                messageParams.template = 'modules/home/views/home.uppy-error.client.view.html';
            } else if (name === 'uploadFormArtistBio') {
                messageParams.title = 'Artist Bio';
                messageParams.hideFooter = false;
                messageParams.hideHeader = false;
                messageParams.backdrop = true;
                messageParams.okText = 'Close';
                messageParams.template = 'modules/home/views/home.upload.basic.artist-bio.client.view.html';
            } else if (name === 'rerunReportArtworkTasks') {
                messageParams.size = 'xxl';
                messageParams.hideFooter = false;
                messageParams.hideHeader = true;
                messageParams.backdrop = true;
                messageParams.okText = 'Close';
                messageParams.template =
                    'modules/admin-area/views/admin-area.services.re-run.report.artwork-tasks.client.view.html';
            } else if (name === 'artworkInfoArtistBio') {
                messageParams.hideFooter = false;
                messageParams.backdrop = true;
                messageParams.okText = 'Close';
                messageParams.template = 'modules/artwork-info/views/artwork-info.artwork.artist-bio.client.view.html';
            } else if (name === 'artworkInfoEventJudgeComments') {
                // messageParams.hideFooter = false;
                messageParams.backdrop = true;
                // messageParams.okText = 'Close';
                messageParams.template =
                    'modules/artwork-info/views/artwork-info.artwork.event-judge-comments.client.view.html';
            } else if (name === 'cloudUploadsProgress') {
                messageParams.size = 'lg';
                messageParams.backdrop = 'static';
                messageParams.hideFooter = true;
                messageParams.template = 'modules/home/views/home.cloud-uploads-progress.client.view.html';
            } else if (name === 'submissionInfo') {
                messageParams.size = 'lg';
                messageParams.backdrop = true;
                messageParams.hideHeader = true;
                messageParams.hideFooter = false;
                messageParams.okText = 'Close';
                messageParams.template = 'modules/home/views/home.event.submission-info.client.view.html';
            } else if (name === 'popup') {
                messageParams.backdrop = 'static';
                messageParams.template = 'modules/home/views/home.popup.client.view.html';
            }

            var deferred = $q.defer();
            $timeout(function () {
                deferred.resolve(Messages.openMessage($rootScope, messageParams));
            });
            return deferred.promise;
        };
    },
]);
