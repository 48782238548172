'use strict';

angular.module('devices').controller('DevicesSelectedDebuggingController', [
    '$rootScope',
    'DevicesDataService',
    'Messages',
    '_',
    function ($rootScope, DevicesDataService, Messages, _) {
        var self = this;
        this.Messages = Messages;
        this.DevicesDataService = DevicesDataService;

        this.init = function () {
            self.debuggingEnabled = self.params.selected.debugging_enabled;
            self.debuggingEmails = self.params.selected.debugging_emails;
            self.isAATV = self.params.isAATV ?? false;
            self.playerArtworks = [];
            self.selectedArtworkIdInPlayer = null;
        };

        this.sendCommandToPlayer = function (command) {
            DevicesDataService.sendCommandToPlayer(command, [self.params.selected.id]);
        };

        this.playSound = function (soundId) {
            var snd = new Audio('modules/devices/img/' + soundId + '.wav'); // buffers automatically when created
            snd.play();
        };

        this.setDebuggingOptions = function () {
            DevicesDataService.setDebuggingOptions(
                self.params.selected.id,
                self.debuggingEnabled,
                self.debuggingEmails
            ).then(
                function (res) {
                    $rootScope.$broadcast(
                        'debugging_changed',
                        self.params.selected.id,
                        self.debuggingEnabled,
                        self.debuggingEmails
                    );
                    Messages.ok();
                    var messageParams = {};
                    messageParams.title = 'Debugging Options';
                    messageParams.message = 'Debugging options were updated';
                    Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.title = 'Debugging Options';
                    messageParams.message = 'There was a problem updating debugging options.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.loadDeviceArtworks = function () {
            DevicesDataService.getDeviceArtworks(self.params.selected.id).then(
                (res) => {
                    self.playerArtworks = JSON.parse(res);
                    if (self.playerArtworks.v) {
                        // Tizen recent_playlist is different
                        delete self.playerArtworks.v;
                        self.playerArtworks = Object.keys(self.playerArtworks);
                    }
                    self.playerArtworks = _.map(self.playerArtworks, (artworkId) => {
                        return { name: Math.abs(parseInt(artworkId)), value: artworkId };
                    });
                    // self.playerArtworks = (self.playerArtworks.map(artworkId => Math.abs(parseInt(artworkId)))).sort();
                },
                (err) => {
                    var messageParams = {};
                    messageParams.title = 'Debugging Options - load player artwork';
                    messageParams.message = 'There was a problem loading player artworks.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };
    },
]);
